/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import { Link } from "react-router-dom";

function Home() {
  return (
    <>
      <Hero />
    </>
  );
}

export default Home;
